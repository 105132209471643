import Cookie from "js-cookie";

const checkToken = Cookie.get("access_token");

export const customRoutes = !checkToken
  ? {
      redirects: {
        "/": "/login",
      },
      routes: [
        {
          type: "application",
          name: "@gtt/login",
        },
      ],
    }
  : {
      routes: [
        {
          type: "application",
          name: "@gtt/nav",
        },
        {
          type: "route",
          path: "admin",
          routes: [{ type: "application", name: "@gtt/admin" }],
        },
        {
          type: "route",
          path: "help-center",
          routes: [{ type: "application", name: "@gtt/portal-help-center" }],
        },
        {
          type: "route",
          path: "user",
          routes: [{ type: "application", name: "@gtt/user" }],
        },
        {
          type: "route",
          path: "notification",
          routes: [{ type: "application", name: "@gtt/notification" }],
        },
        {
          type: "route",
          path: "content-manage",
          routes: [{ type: "application", name: "@gtt/content" }],
        },
        {
          type: "route",
          path: "referral",
          routes: [{ type: "application", name: "@gtt/referral" }],
        },
        {
          type: "route",
          path: "home-manage",
          routes: [{ type: "application", name: "@gtt/content" }],
        },
        {
          type: "route",
          path: "banner-manage",
          routes: [{ type: "application", name: "@gtt/banner-manage" }],
        },
        {
          type: "route",
          path: "core",
          routes: [{ type: "application", name: "@gtt/core" }],
        },
        {
          type: "route",
          path: "privilege",
          routes: [{ type: "application", name: "@gtt/privilege" }],
        },
        {
          type: "route",
          path: "app-preview",
          routes: [{ type: "application", name: "@gtt/app-preview" }],
        },
      ],
    };
